@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Noto+Kufi+Arabic:wght@300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .grid-wrapper {
    @apply grid-cols-1;
  }
  @media (min-width: 640px) {
    .grid-wrapper {
      grid-template-columns: repeat(auto-fill, minmax(19em, 1fr));
    }
  }
  .form-error {
    @apply text-sm text-red-500 mt-2 table empty:hidden;
  }
  .form-file {
    @apply file:me-4 file:py-2 file:px-4 
      file:rounded file:border-0
      file:text-sm file:font-semibold file:transition-all
      file:bg-primary/20 file:text-primary
      group-hover:file:bg-primary/30 hover:file:bg-primary/30 cursor-pointer text-sm text-gray-500;
  }
}

*,
*::after,
*::before {
  outline: none !important;
}
:root {
  font-family: 'Inter', 'Noto Kufi Arabic', Helvetica, Arial, sans-serif;
  @apply !text-base !font-medium !text-start;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

:is(.form-input, .form-select, .form-textarea, .form-multiselect) {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  width: 100%;
  @apply bg-gray-100 rounded p-3 px-4 text-sm;
}

.form-file {
  @apply form-input;
}
.form-outline {
  border-width: 1px !important;
  border-style: solid !important;
  @apply !border-gray-200 bg-white;
}

.btn-with-icon {
  @apply shrink-0 flex items-center  px-3 gap-2 py-2 rounded  text-sm relative text-white justify-center;
}

.outline-btn {
  @apply text-gray-500 bg-white border border-gray-300;
}

.form-label {
  @apply block text-sm text-gray-500 text-start;
}

.form-group {
  @apply space-y-2;
}

.menu-item {
  @apply flex items-center gap-3 text-gray-500 py-2 px-3;
}

.action-btn {
  @apply text-xs text-center flex items-center gap-2 font-medium;
}

:is([disabled='disabled'], [disabled='true'], [disabled]) {
  @apply opacity-50 cursor-not-allowed;
}

.form-select {
  @apply pe-8 rtl:ps-8 rtl:pe-4;
}

.Toastify__toast {
  @apply !shadow-2xl !shadow-gray-800/10  !bg-gray-800;
}

.Toastify__toast .Toastify__toast-body {
  font-family: 'Inter', 'Noto Kufi Arabic', Helvetica, Arial, sans-serif;
  @apply font-medium text-sm text-white;
}

.styled-table {
  @apply border-collapse w-full text-sm whitespace-nowrap;
}

.styled-table th,
td {
  @apply text-start text-sm py-3 px-4;
}

.styled-table th {
  @apply font-medium text-gray-500;
}

.styled-table thead,
tbody {
  @apply divide-y divide-gray-200;
}

.edit-btn {
  @apply btn-with-icon bg-gray-100 !text-gray-600 !text-xs;
}

[data-type='status-track'] [data-type='status-track-slide']::before,
[data-type='status-track'] [data-type='status-track-slide']::after {
  content: '';
  @apply w-1/2 border-t-2 border-dashed border-gray-300 absolute top-1/2 -translate-y-1/2;
}

[data-type='status-track'] [data-type='status-track-slide']::before {
  @apply left-0 rtl:right-0 rtl:left-auto;
}

[data-type='status-track'] [data-type='status-track-slide']::after {
  @apply right-0 rtl:left-0 rtl:right-auto;
}

[data-type='status-track']:first-child [data-type='status-track-slide']::before {
  @apply hidden;
}
[data-type='status-track']:last-child [data-type='status-track-slide']::after {
  @apply hidden;
}

.qr-code-wrapper {
  @apply h-auto relative bg-black p-1 rounded-md;
}
.qr-code-wrapper::before {
  content: '';
  @apply absolute w-[calc(100%-3rem)] h-full bg-white top-0 left-1/2 -translate-x-1/2 scale-105;
}

.qr-code-wrapper::after {
  content: '';
  @apply absolute h-[calc(100%-3rem)] w-full bg-white left-0 top-1/2 -translate-y-1/2 scale-105;
}

.qr-code-wrapper svg {
  @apply z-10 relative w-full h-full border-8 border-white rounded;
}

/* Works for Chrome, Safari, Edge, Opera */
input[aria-controls='false']::-webkit-outer-spin-button,
input[aria-controls='false']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[aria-controls='false'][type='number'] {
  -moz-appearance: textfield;
}

.ql-snow {
  @apply !border-gray-200;
}

.ql-toolbar {
  @apply !rounded-t-md flex items-center gap-1 flex-wrap;
}

.ql-toolbar .ql-formats {
  @apply !m-0 !flex flex-wrap gap-1;
}

.ql-toolbar .ql-formats button {
  @apply text-base text-gray-600 !w-auto !h-auto !float-none !flex items-center justify-center transition-all;
}
.ql-toolbar .ql-formats button.ql-active {
  @apply !bg-primary !text-black;
}

.ql-toolbar .ql-formats button svg {
  @apply w-4 h-4 !float-none;
}
.ql-container {
  @apply !rounded-b-md;
}

.actions {
  @apply flex gap-2;
}
.actions * {
  @apply shrink-0;
}
.ql-picker-label {
  @apply !border-none !text-gray-600 !text-sm;
}
.ql-picker-label svg {
  @apply !hidden;
}

.fc-event-title {
  @apply truncate flex-1 shrink-0;
}

